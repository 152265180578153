import clsx from 'clsx';
import Image from 'next/image';

import PaperBg from '@/assets/images/paper-bg.png';
import RedactedLogo from '@/assets/images/redacted-black.png';
import TapeBg from '@/assets/images/tape-bg.svg';

import styles from './PaperBox.module.scss';

export const PaperBox = ({
    title,
    children,
    flipTitle,
    tapeBoxClassName,
    paperBoxClassName,
    contentClassName,
    vertical,
    redactedTape,
}: {
    title?: string;
    children: string | JSX.Element | JSX.Element[];
    flipTitle?: boolean;
    tapeBoxClassName?: string;
    paperBoxClassName?: string;
    contentClassName?: string;
    vertical?: boolean;
    redactedTape?: boolean;
}) => {
    return (
        <>
            <div
                className={clsx(styles.tapeBox, tapeBoxClassName, {
                    [styles.flip]: flipTitle,
                    [styles.redactedWrapper]: redactedTape,
                })}
            >
                {title ? (
                    <>
                        <TapeBg className={styles.tape} role="presentation" />
                        <h3 className={styles.title}>{title}</h3>
                    </>
                ) : redactedTape ? (
                    <Image
                        className={clsx(styles.tape, styles.redacted)}
                        src={RedactedLogo}
                        alt="Redacted"
                        width={700}
                        role="img"
                        aria-label="Redacted title"
                    />
                ) : (
                    <></>
                )}
            </div>
            <div
                className={clsx(styles.paperBox, paperBoxClassName, {
                    [styles.vertical]: vertical,
                })}
            >
                <Image
                    className={styles.paper}
                    src={PaperBg}
                    alt=""
                    role="presentation"
                    fill
                    quality={100}
                />
                <div className={clsx(styles.paperContent, contentClassName)}>{children}</div>
            </div>
        </>
    );
};
