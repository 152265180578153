'use client';

import clsx from 'clsx';
import { Suspense } from 'react';

import { CircleSpinner } from '@/components/circle-spinner/CircleSpinner.component';
import { PaperBox } from '@/components/paper-box/PaperBox.component';

import styles from './CommunityStat.module.scss';

export const CommunityStat = ({
    strings,
    data,
    index,
}: {
    strings: any;
    data: any;
    index: number;
}) => {
    const tiltClass = `tilted-${index}`;
    return (
        <div className={styles.container}>
            <Suspense fallback={<CircleSpinner />}>
                <PaperBox
                    paperBoxClassName={clsx(styles[tiltClass], styles.tilted)}
                    tapeBoxClassName={styles.hidden}
                >
                    {data && data[0] === 'error' ? (
                        <h4 className={styles.title}>{strings.error}</h4>
                    ) : (
                        <>
                            <h4 className={styles.title}>{strings.title}</h4>
                            <h3 className={styles.data}>{data?.toLocaleString()}</h3>
                        </>
                    )}
                </PaperBox>
            </Suspense>
        </div>
    );
};
