'use client';

import { Suspense } from 'react';

import { CircleSpinner } from '@/components/circle-spinner/CircleSpinner.component';

import styles from './Leaderboard.module.scss';
import { LeaderboardTable } from './LeaderboardTable.component';

export const Leaderboard = ({ strings, data }: { strings: any; data: any }) => {
    const stringsWithDataStrings = {
        value_field: data.valueField,
        monthly_api_id: data.monthlyApiId,
        all_time_api_id: data.allTimeApiId,
        leaderboard_error: data.leaderboard_error,
        title: data.title,
        ...strings,
    };

    return (
        <>
            <Suspense fallback={<CircleSpinner />}>
                <div className={styles.leaderboardContainer}>
                    <LeaderboardTable strings={stringsWithDataStrings} />
                </div>
            </Suspense>
        </>
    );
};
