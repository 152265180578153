'use client';

import styles from './LeaderboardsHeader.module.scss';

export const LeaderboardsHeader = ({ strings }: { strings: any }) => {
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>{strings.title}</h3>
            <h5 className={styles.disclaimer}>{strings.disclaimer}</h5>
        </div>
    );
};
