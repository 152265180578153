'use client';

import { clsx } from 'clsx';
import Image from 'next/image';

import styles from './LeaderboardNavigation.module.scss';

export const LeaderboardNavigation = ({
    isDisabled,
    direction,
    clickAction,
}: {
    isDisabled: boolean;
    direction: string;
    clickAction: any;
}) => {
    const arrowDirection = direction === 'next' ? 'next' : 'previous';
    return (
        <>
            <button onClick={clickAction} disabled={isDisabled}>
                <Image
                    className={clsx(styles.arrow, styles[arrowDirection])}
                    src="/assets/images/arrow.svg"
                    alt="arrow"
                    role="navigation"
                    width="24"
                    height="24"
                />
            </button>
        </>
    );
};
