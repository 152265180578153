'use client';

import { usePathname } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { useCallback, useState, useEffect } from 'react';

import { CircleSpinner } from '@/components/circle-spinner/CircleSpinner.component';
import { TextBlock } from '@/components/text-block/TextBlock.component';
import {
    ALL_TIME_LEADERBOARDS_ENDPOINT,
    MONTHLY_LEADERBOARDS_ENDPOINT,
    PLAYER_PLACEMENT_ENDPOINT,
} from '@/constants';

import { updateLeaderboardAction } from '../../actions/updateLeaderboard';

import { LeaderboardNavigation } from './LeaderboardNavigation.component';
import styles from './LeaderboardTable.module.scss';
import { LeaderboardTimePeriod } from './LeaderboardTimePeriod.component';

export const LeaderboardTable = ({ strings }: { strings: any }) => {
    const [records, setRecords] = useState<any[]>();
    const [currentPage, setCurrentPage] = useState(0);
    const [monthlyOrAllTime, setMonthlyOrAllTime] = useState(strings.all_time_api_id);
    const [personalRecords, setPersonalRecords] = useState<any[]>([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [paginationTokens, setPaginationTokens] = useState<string[]>(['']);
    const [pageCount, setPageCount] = useState(0);
    const { data: session, status } = useSession();
    const pathname = usePathname();

    const getUrl = useCallback(
        (kraftonId?: string, period?: string) => {
            const url: string = kraftonId
                ? PLAYER_PLACEMENT_ENDPOINT
                : period === strings.monthly_api_id
                  ? MONTHLY_LEADERBOARDS_ENDPOINT
                  : ALL_TIME_LEADERBOARDS_ENDPOINT;
            return url;
        },
        [strings.monthly_api_id]
    );

    const fetchLeaderboardData = useCallback(
        async (
            apiId: string,
            pageToken?: string,
            pageCount?: number,
            kraftonId?: string,
            accessToken?: string
        ) => {
            const url = getUrl(kraftonId, apiId);
            const fetchedData = await updateLeaderboardAction(
                url,
                apiId,
                pageToken,
                pageCount,
                kraftonId,
                accessToken
            );

            if (fetchedData && fetchedData.length >= 0 && fetchedData[0] === 'error') {
                if (kraftonId) {
                    setPersonalRecords(fetchedData);
                    return fetchedData;
                }
                setRecords(fetchedData);
                setCurrentPage(0);
                setPaginationTokens(['']);
                setPageCount(0);
                return fetchedData;
            }
            if (fetchedData && fetchedData.length >= 0 && fetchedData[0]) {
                if (kraftonId) {
                    setPersonalRecords(fetchedData);
                    return fetchedData;
                }
                const nextPageToken = fetchedData[1].NextPageToken as string;
                setPaginationTokens((paginationTokens) =>
                    !paginationTokens.includes(fetchedData[1]?.NextPageToken) &&
                    fetchedData[1]?.NextPageToken !== ''
                        ? [...paginationTokens, nextPageToken]
                        : [...paginationTokens]
                );
                setRecords(fetchedData[0]);
                setPageCount(fetchedData[1].TotalPages);
                return fetchedData;
            }
        },
        [getUrl]
    );

    const prevPage = async () => {
        disableOrEnableButtons(true);
        const prevPage = currentPage - 1 >= 0 ? currentPage - 1 : 0;
        await fetchLeaderboardData(monthlyOrAllTime, paginationTokens[prevPage], 5, '', '');
        setCurrentPage(prevPage);
        disableOrEnableButtons(false);
        return null;
    };

    const nextPage = async () => {
        disableOrEnableButtons(true);
        const nextPage = paginationTokens[currentPage + 1] ? currentPage + 1 : 0;
        await fetchLeaderboardData(monthlyOrAllTime, paginationTokens[nextPage], 5, '', '');
        setCurrentPage(nextPage);
        disableOrEnableButtons(false);
        return null;
    };

    const switchTimePeriod = async (newPeriod: string) => {
        if (newPeriod === monthlyOrAllTime) return false;
        disableOrEnableButtons(true);
        setMonthlyOrAllTime(newPeriod);
        setCurrentPage(0);
        setPaginationTokens(['']);
        await fetchLeaderboardData(newPeriod, '', 5, '', '');
        disableOrEnableButtons(false);
        return null;
    };

    const getUserPosition = useCallback(async () => {
        if (!session?.user?.sub) return false;
        disableOrEnableButtons(true);
        const getNewUserPosition = await fetchLeaderboardData(
            monthlyOrAllTime,
            '',
            5,
            session?.user?.sub,
            session?.user?.accessToken
        );
        disableOrEnableButtons(false);
        return getNewUserPosition;
    }, [monthlyOrAllTime, session?.user?.sub, session?.user?.accessToken, fetchLeaderboardData]);

    const disableOrEnableButtons = (onOrOff: boolean) => {
        setButtonsDisabled(onOrOff);
    };

    const convertSpeed = (runtime: number) => {
        const date = new Date(runtime * 1000);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedTime;
    };

    useEffect(() => {
        const initialFetch = async () => {
            const fetchedData = await fetchLeaderboardData(strings.all_time_api_id, '', 5, '', '');
            disableOrEnableButtons(false);
            return fetchedData;
        };
        initialFetch();
    }, [fetchLeaderboardData, strings.all_time_api_id]);

    useEffect(() => {
        if (session?.user?.sub && (!personalRecords || personalRecords.length <= 0)) {
            getUserPosition();
        }
    }, [session, status, personalRecords, getUserPosition]);

    return (
        <>
            <div className={styles.headerContainer}>
                <h2>{strings.title}</h2>
                {records ? (
                    <div className={styles.timePeriodBtns}>
                        <LeaderboardTimePeriod
                            clickAction={switchTimePeriod}
                            isDisabled={buttonsDisabled}
                            label={strings.all_time_button_label}
                            monthlyOrAll={strings.all_time_api_id}
                            isActive={monthlyOrAllTime === strings.all_time_api_id ? true : false}
                            extraClass="allTime"
                        />
                        <LeaderboardTimePeriod
                            clickAction={switchTimePeriod}
                            isDisabled={buttonsDisabled}
                            label={strings.this_month_button_label}
                            monthlyOrAll={strings.monthly_api_id}
                            isActive={monthlyOrAllTime === strings.monthly_api_id ? true : false}
                            extraClass="thisMonth"
                        />
                    </div>
                ) : null}
            </div>
            {records ? (
                <>
                    <table className={styles.dataTable}>
                        <tbody>
                            <tr className={styles.headersRow}>
                                <td>
                                    <TextBlock content={strings.ranking_field} />
                                </td>
                                <td>
                                    <TextBlock content={strings.platform_field} />
                                </td>
                                <td>
                                    <TextBlock content={strings.value_field} />
                                </td>
                            </tr>
                            {records && records.length > 0 && records[0] !== 'error' ? (
                                records?.map((record: any, index: number) => (
                                    <tr key={index} className={styles.dataRow}>
                                        <td>{record.Rank.toString().padStart(2, '0')}</td>
                                        <td>{record.platform}</td>
                                        <td>
                                            {strings.all_time_api_id.includes('speed_run')
                                                ? convertSpeed(record.StatValue)
                                                : record.StatValue}
                                        </td>
                                    </tr>
                                ))
                            ) : records && (records[0] === 'error' || records.length <= 0) ? (
                                <tr className={styles.leaderboardError}>
                                    <td>{strings.leaderboard_error}</td>
                                </tr>
                            ) : (
                                <CircleSpinner />
                            )}
                        </tbody>
                    </table>
                    {records && records.length > 0 && records[0] !== 'error' ? (
                        <div className={styles.leaderboardNav}>
                            <LeaderboardNavigation
                                isDisabled={buttonsDisabled || currentPage === 0}
                                clickAction={prevPage}
                                direction="previous"
                            />
                            <div
                                className={styles.pageCount}
                            >{`${currentPage + 1} / ${pageCount}`}</div>
                            <LeaderboardNavigation
                                isDisabled={buttonsDisabled || currentPage === pageCount - 1}
                                clickAction={nextPage}
                                direction="next"
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <div className={styles.spinnerContainer}>
                    <CircleSpinner className={styles.spinner} />
                </div>
            )}
            {pathname.includes('leaderboards') ? (
                <>
                    {personalRecords.length > 0 && personalRecords[0] !== 'error' ? (
                        <>
                            <div className={styles.personalBest}>
                                {strings.personal_stats_header || 'My Best Ranking'}
                            </div>
                            <table className={styles.personalStats}>
                                <tbody>
                                    {personalRecords?.map((record: any, idx: number) => (
                                        <tr className={styles.dataRow} key={idx}>
                                            <td>{record.Rank.toString().padStart(2, '0')}</td>
                                            <td>{session?.user?.nickname}</td>
                                            <td>
                                                {strings.all_time_api_id.includes('speed_run')
                                                    ? convertSpeed(record.StatValue)
                                                    : record.StatValue}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : personalRecords && personalRecords[0] === 'error' ? (
                        <div className={styles.personalRecordsError}>{strings.personal_error}</div>
                    ) : (
                        <div
                            onClick={() => {
                                signIn('krafton');
                            }}
                            className={styles.personalStatsSignIn}
                        >
                            {strings.personal_stats_login_cta}
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
};
